import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vincent/Documents/Develop/Web/PersonalWebsite/website/src/components/layout-markdown.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">
        <p parentName="li">{`What do we want to improve?`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Feedback loop: how can we reduce the time needed from changing code, data or assets, to seeing the result on screen?`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`Do I have any manual actions to repeat every time I change my code?`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`How much work is it to switch between features I'm working on?`}</p>
                <ul parentName="li">
                  <li parentName="ul">
                    <p parentName="li">{`Do I need to set up test data, accounts, etc.?`}</p>
                  </li>
                  <li parentName="ul">
                    <p parentName="li">{`How many services do I need to start and restart between work contexts?`}</p>
                  </li>
                </ul>
              </li>
              <li parentName="ul">
                <p parentName="li">{`How many different UI states do I need to check for a single action?`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`How fast does my code compile?`}</p>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Developers are not the only ones working on the product`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`How can we show the progress we're making as quickly as possible with minimal effort both to safely publish and to view changes?`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`How can I reduce bottlenecks for the rest of the organization to make changes that shouldn't involve developers?`}</p>
                <ul parentName="li">
                  <li parentName="ul">
                    <p parentName="li">{`Gathering new types of analytics should not depend on developers.`}</p>
                  </li>
                  <li parentName="ul">
                    <p parentName="li">{`Changing and validating the visual designs should not depend on developers.`}</p>
                  </li>
                </ul>
              </li>
              <li parentName="ul">
                <p parentName="li">{`How can we relate different parts of the program to other parts of the organization?`}</p>
                <ul parentName="li">
                  <li parentName="ul">
                    <p parentName="li">{`How easy is it to find out why a certain design decision was made?`}</p>
                  </li>
                  <li parentName="ul">
                    <p parentName="li">{`How can I see what still needs to work when I change some code?`}</p>
                  </li>
                  <li parentName="ul">
                    <p parentName="li">{`How easily can I make cost projections?`}</p>
                  </li>
                  <li parentName="ul">
                    <p parentName="li">{`How easily can I understand user behavior?`}</p>
                  </li>
                  <li parentName="ul">
                    <p parentName="li">{`How can I see in which context which data is queried how and why?`}</p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`The resulting user experience should be fast`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`Pages should load as quickly as possible`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`The UI should run efficiently, generate as little system load as possible and respond quickly to user input (including scrolling)`}</p>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      